<script setup lang="ts">
import { Tippy } from 'vue-tippy'
import type { NovaBoxCreatorHomeChatButtonProps } from './NovaBoxCreatorHomeChatButton.types'
import { ChatMode } from '@sendbird'
const { gtEvent } = useGoogleTag()
const { creator } = defineProps<NovaBoxCreatorHomeChatButtonProps>()
const { t } = useI18n()
const { enterChat, createChat } = useSendbirdGate()
const userStore = useUserStore()
const tippyRef = ref<typeof Tippy | null>(null)
const loading = ref(false)
const isEqualUser = computed(() => creator.userSn === userStore.user?.userSn)

const hideTippy = () => {
  tippyRef.value?.hide()
}

const changeLoading = (_loading: boolean) => {
  loading.value = _loading
}

const handleOnCreateChat = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '팬 채팅방 개설',
    eventLabel: t('sendbird.createChatChannel.actions.create'),
    eventSlot: '크리에이터 홈 > 팬 채팅방 개설하기',
    eventI18nAddr: useKoreanTranslation(
      'sendbird.createChatChannel.actions.create'
    ),
    eventComponent: 'Button',
  })
  if (loading.value) return
  createChat({ mode: 'showCreateChannelModal', onLoading: changeLoading })
  hideTippy()
}

const handleOnEnterChat = (mode: ChatMode) => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '채팅 목록',
    eventLabel: t('sendbird.enterChatChannel.actions.enter'),
    eventSlot: '크리에이터 홈 > 채팅 목록',
    eventI18nAddr: useKoreanTranslation(
      'sendbird.enterChatChannel.actions.enter'
    ),
    eventComponent: 'Button',
  })
  if (loading.value) return
  switch (mode) {
    case ChatMode.CHANNEL:
      if (!creator.channelUrl) {
        useToast(t('sendbird.enterChatChannel.errors.createChannelNotYet'))
      } else {
        enterChat({
          mode,
          channelUrl: creator.channelUrl,
          onLoading: changeLoading,
        })
      }
      break
    case ChatMode.CHANNEL_LIST:
      enterChat({
        mode,
        onLoading: changeLoading,
      })
      break
    default:
      break
  }
}

const showCondition = () => {
  if (!userStore.isSignIn && creator.channelUrl) return true

  // 같은 사용자가 아니고 크리에이터가 채널을 개섫했다면 툴팁 오픈
  if (!isEqualUser.value && creator.channelUrl) return true

  // 같은 사용자이며, 채널을 개설하지 않았다면 툴팁 오픈
  return isEqualUser.value && !creator.channelUrl
}

const handleOnOpenChatGate = (mode: ChatMode) => {
  if (loading.value) return

  if (creator.userSn === userStore.user?.userSn && !userStore.user?.channelUrl)
    return
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '채팅 버튼 클릭',
    eventLabel: 'chat',
    eventSlot: '유저가 크리에이터 홈 채팅 아이콘 클릭',
    eventI18nAddr: '',
    eventComponent: 'Button',
  })
  switch (mode) {
    case ChatMode.CHANNEL:
      if (!creator.channelUrl) {
        useToast(t('sendbird.enterChatChannel.errors.createChannelNotYet'))
      } else {
        enterChat({
          mode,
          channelUrl: creator.channelUrl,
          onLoading: changeLoading,
        })
      }
      break
    case ChatMode.CHANNEL_LIST:
      enterChat({
        mode,
        onLoading: changeLoading,
      })
      break
    default:
      break
  }
}
</script>

<template>
  <ClientOnly>
    <div :class="['chat-button-wrap', { loading }]">
      <div class="inner">
        <Tippy
          ref="tippyRef"
          :append-to="'parent'"
          :interactive="true"
          :theme="'chat-gate'"
          :placement="'bottom'"
          :on-show="showCondition"
          :tag="'button'"
          :role="'button'"
          @click.stop="() => handleOnOpenChatGate(ChatMode.CHANNEL)"
        >
          <div class="button-wrap">
            <NovaIcon
              :icon="{ type: 'outline', name: 'chat' }"
              class="chat-icon"
            />

            <NovaLoadingIndicator
              v-if="loading"
              :size="80"
              :bg-bright="'dark'"
              :fill="false"
              class="loading"
            />
          </div>

          <template #content>
            <div v-if="!isEqualUser && creator.channelUrl" class="chat-gate">
              {{ t('sendbird.enterChatChannel.enterCreatorChannel') }}
            </div>

            <div v-if="isEqualUser && !creator.channelUrl" class="chat-gate">
              <button
                type="button"
                class="chat-gate-item"
                @click.stop="handleOnCreateChat"
              >
                {{ t('sendbird.createChatChannel.actions.create') }}
              </button>
              <button
                type="button"
                class="chat-gate-item"
                @click.stop="() => handleOnEnterChat(ChatMode.CHANNEL_LIST)"
              >
                {{ t('sendbird.enterChatChannel.actions.enter') }}
              </button>
            </div>
          </template>
        </Tippy>
      </div>
    </div>
  </ClientOnly>
</template>

<style lang="scss" scoped>
.chat-button-wrap {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &.loading {
    > .inner .button-wrap .chat-icon {
      opacity: 0.3;
    }
  }

  > .inner {
    .button-wrap {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 48px;
      height: 48px;
      background-color: $color-text-4;
      border-radius: 8px;

      .chat-icon {
        font-size: 32px !important;
        color: $color-white;
      }

      .loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.chat-gate {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .chat-gate-item {
    word-break: keep-all;
    transition: font-weight 0.12s ease-in-out;

    &:hover {
      font-weight: 500;
    }
  }
}
</style>
