<script setup lang="ts">
import type {
  Share,
  NovaBoxHomeActionSharePanelEmits,
  NovaBoxHomeActionSharePanelProps,
} from './NovaBoxHomeActionSharePanel.types'

const emit = defineEmits<NovaBoxHomeActionSharePanelEmits>()
const props = defineProps<NovaBoxHomeActionSharePanelProps>()
const { gtEvent } = useGoogleTag()
const config = useRuntimeConfig()
const creatorStore = useCreatorStore()
const mypageStore = useMyPageStore()
const { t, locale } = useI18n()
const router = useRouter()
const isLoading = ref(false)
const shares = ref<Share[]>([
  {
    id: 'clipboard',
    icon: { type: 'outline', name: 'link' },
    label: t('share.actions.clipboard'),
  },
  {
    id: 'facebook',
    icon: { type: 'outline', name: 'social-facebook' },
    label: t('share.actions.facebook'),
  },
  {
    id: 'twitter',
    icon: { type: 'outline', name: 'social-twitter' },
    label: t('share.actions.twitter'),
  },
  {
    id: 'kakao',
    icon: { type: 'outline', name: 'social-kakao' },
    label: t('share.actions.kakao'),
  },
])

const openLink = async (url: string) => {
  await useNavigations({ external: true, url })
}

const handleOnGoBack = () => {
  emit('goBack')
}

const handleOnShare = (id: string, label: string) => {
  const meta = getLandingMeta()
  switch (id) {
    case 'clipboard':
      useCopyToClipboard(useDeeplinkEntry(meta.landingUrl))
      useToast(t('copyToClipboard'))
      gtEvent('clickEvent', {
        eventCategory: '클릭',
        eventAction: '공유하기 > 링크 복사 버튼 클릭',
        eventLabel: label,
        eventSlot: '다른 유저 홈 > 프로필 상단 버튼 > 공유 하기',
        eventI18nAddr: useKoreanTranslation('share.actions.clipboard'),
        eventComponent: 'Button',
        landingUrl: meta.landingUrl,
      })
      break
    case 'facebook':
      openLink(
        `https://www.facebook.com/sharer/sharer.php?u=${useDeeplinkEntry(
          meta.landingUrl
        )}`
      )
      gtEvent('clickEvent', {
        eventCategory: '클릭',
        eventAction: '공유하기 > 페이스북에 공유 버튼 클릭',
        eventLabel: label,
        eventSlot: '다른 유저 홈 > 프로필 상단 버튼 > 공유 하기',
        eventI18nAddr: useKoreanTranslation('share.actions.facebook'),
        eventComponent: 'Button',
        landingUrl: meta.landingUrl,
      })
      break
    case 'twitter':
      openLink(
        `https://twitter.com/share?url=${useDeeplinkEntry(meta.landingUrl)}`
      )
      gtEvent('clickEvent', {
        eventCategory: '클릭',
        eventAction: '공유하기 > 트위터에 공유 버튼 클릭',
        eventLabel: label,
        eventSlot: '다른 유저 홈 > 프로필 상단 버튼 > 공유 하기',
        eventI18nAddr: useKoreanTranslation('share.actions.twitter'),
        eventComponent: 'Button',
        landingUrl: meta.landingUrl,
      })
      break
    case 'kakao':
      useSendKakaoShare({
        objectType: 'feed',
        content: {
          title: meta.title,
          description: meta.description,
          imageUrl: meta.imageUrl,
          link: {
            mobileWebUrl: useDeeplinkEntry(meta.landingUrl),
            webUrl: useDeeplinkEntry(meta.landingUrl),
          },
        },
      })
      gtEvent('clickEvent', {
        eventCategory: '클릭',
        eventAction: '공유하기 > 카카오톡에 공유 버튼 클릭',
        eventLabel: label,
        eventSlot: '다른 유저 홈 > 프로필 상단 버튼 > 공유 하기',
        eventI18nAddr: useKoreanTranslation('share.actions.kakao'),
        eventComponent: 'Button',
        landingUrl: meta.landingUrl,
      })
      break
    case 'discord':
      break
    default:
      throw new Error('share type is not match')
  }
}

const getLandingMeta = () => {
  const meta = {
    landingUrl: '',
    title: '',
    description: '',
    imageUrl: '',
  }

  // 1. set landing url
  const userPaths = [
    `/user/@${mypageStore.homeProfile?.userNcnm || ''}`,
    `/creator/@${creatorStore.profile?.creatorLink || ''}`,
  ]
  meta.landingUrl = `${config.public.APP_URL}${
    router.resolve({
      path:
        locale.value === 'ko'
          ? userPaths[props.userType]
          : `/${locale.value}${userPaths[props.userType]}`,
    }).href
  }`

  // 2. set title
  const userTitle = [
    `${mypageStore.homeProfile?.userNcnm || ''} | ${config.public.APP_NAME}`,
    `${creatorStore.profile?.userNcnm || ''} | ${config.public.APP_NAME}`,
  ]
  meta.title = userTitle[props.userType]

  // 3. set description
  const userDescription = [
    mypageStore.homeProfile?.proflDc || '',
    creatorStore.profile?.proflDc || '',
  ]
  meta.description = userDescription[props.userType]

  // 4. set imageUrl
  const userImageUrl = [
    mypageStore.homeProfile?.userProflUrl || '',
    creatorStore.profile?.userProflUrl || '',
  ]
  meta.imageUrl = userImageUrl[props.userType]

  return meta
}
</script>

<template>
  <div :class="['share-panel', { 'is-loading': isLoading }]">
    <div class="panel-header">
      <NovaButtonIcon
        :icon="{ type: 'outline', name: 'arrow-left' }"
        class="btn-go-back"
        @click.stop="handleOnGoBack"
      />
    </div>

    <div class="actions">
      <button
        v-for="share in shares"
        :key="share.id"
        class="share-item"
        type="button"
        @click.stop="handleOnShare(share.id, share.label)"
      >
        <NovaIcon :icon="share.icon" :class="['icon', share.id]" />

        <span class="label">{{ share.label }}</span>
      </button>

      <div v-if="isLoading" class="loading-indicator-wrap">
        <NovaLoadingIndicator :bg-bright="'light'" :size="60" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.share-panel {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 15px 0 20px;

  &.is-loading {
    pointer-events: none;

    .actions {
      opacity: 0.3;
      pointer-events: none;
    }

    .loading-indicator-wrap {
      display: flex;
    }
  }

  .actions {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 6px;

    .share-item {
      display: flex;
      align-items: center;
      gap: 8px;

      @media (hover: hover) {
        &:hover {
          .label {
            color: $color-black;
          }
        }
      }

      > .icon {
        &.clipboard {
          color: $color-text-2;
        }
        &.facebook {
          color: $color-facebook;
        }
        &.twitter {
          color: $color-twitter;
        }
        &.kakao {
          color: $color-kakao;
        }
        //&.discord {
        //  color: $color-discord;
        //}
      }

      .label {
        @include text-style($text-body-14-regular);
        color: $color-text-2;
        transition: color 0.2s ease-in-out;
      }
    }

    .loading-indicator-wrap {
      position: absolute;
      top: 0;
      left: 0;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
