<script setup lang="ts">
import { Tippy } from 'vue-tippy'
import type {
  HomeActionType,
  HomeActionItem,
  NovaBoxHomeActionProps,
} from './NovaBoxHomeAction.types'
import { BlockType } from '@composables/useBlockStatus'

const props = defineProps<NovaBoxHomeActionProps>()

const userStore = useUserStore()
const { showRemoveFollowerModal } = useFollow()
const tippyRef = ref<typeof Tippy | null>(null)
const { t, locale, messages } = useI18n()
const homeActions = computed(
  () =>
    Object.keys(messages.value[locale.value].homeActions)
      .map((key) => ({
        id: key,
        name: t(`homeActions.${key}`),
        action: getAction[key as HomeActionType],
      }))
      .filter((item) =>
        filterAction(item.id as HomeActionType)
      ) as HomeActionItem[]
)

const onShowMobileAction = async () => {}

const filterAction = (actionType: HomeActionType) => {
  const { followerAt, blockingAt } = props.source

  // 로그인 상태에서만 노출되는 액션
  const signInAllowActions: HomeActionType[] = ['blocking', 'removeFollower']

  // 비로그인 상태이고 로그인이 필요한 액션이 필터에 걸리면 비노출
  if (!userStore.isSignIn && signInAllowActions.includes(actionType))
    return false

  // 홈 사용자가 현재 사용자를 팔로우 하고 있지 않으면 팔로워 삭제하기 액션 비노출
  if (
    (blockingAt === 'Y' || followerAt === 'N') &&
    actionType === 'removeFollower'
  )
    return false

  // 홈 사용자를 현재 사용자가 차단 하고 있으면 차단하기 액션 비노출
  if (blockingAt === 'Y' && actionType === 'blocking') return false

  // 위 필터 조건을 충족하지 않는 액션은 노출
  return true
}

const getAction: { [Property in HomeActionType]: () => void } = {
  // 공유: NovaBoxHomeActionPanel.vue 내부에서 슬라이드 처리함
  share: () => {},
  // 차단
  blocking: () => {
    const params = {
      target: props.source.target,
      type: BlockType.BLOCKING,
      userSn: props.source.userSn,
      userNcnm: props.source.userNcnm,
      isDonation: props.source.isDonation,
    }
    callBlockDialog(params)
  },
  // 팔로워 삭제
  removeFollower: () =>
    showRemoveFollowerModal(props.source.userSn, props.source.target),
}
</script>

<template>
  <Tippy
    ref="tippyRef"
    :append-to="'parent'"
    :interactive="true"
    :placement="'bottom-end'"
    :theme="'popover'"
    :trigger="'click'"
    @click.stop="onShowMobileAction"
  >
    <NovaBoxHomeActionButton />

    <template #content>
      <ClientOnly>
        <NovaBoxHomeActionPanel
          :user-type="source.target"
          :home-actions="homeActions"
        />
      </ClientOnly>
    </template>
  </Tippy>
</template>
